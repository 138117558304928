/* eslint-disable */
// @ts-ignore
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "";

export enum Gender {
  NO_GENDER = 0,
  FEMALE = 1,
  MALE = 2,
  OTHER = 3,
  NOT_DISCLOSED = 4,
  UNRECOGNIZED = -1,
}

export function genderFromJSON(object: any): Gender {
  switch (object) {
    case 0:
    case "NO_GENDER":
      return Gender.NO_GENDER;
    case 1:
    case "FEMALE":
      return Gender.FEMALE;
    case 2:
    case "MALE":
      return Gender.MALE;
    case 3:
    case "OTHER":
      return Gender.OTHER;
    case 4:
    case "NOT_DISCLOSED":
      return Gender.NOT_DISCLOSED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Gender.UNRECOGNIZED;
  }
}

export function genderToJSON(object: Gender): string {
  switch (object) {
    case Gender.NO_GENDER:
      return "NO_GENDER";
    case Gender.FEMALE:
      return "FEMALE";
    case Gender.MALE:
      return "MALE";
    case Gender.OTHER:
      return "OTHER";
    case Gender.NOT_DISCLOSED:
      return "NOT_DISCLOSED";
    case Gender.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SkillType {
  ST_NONE = 0,
  ST_STATE = 1,
  ST_STATE_LIFE = 2,
  UNRECOGNIZED = -1,
}

export function skillTypeFromJSON(object: any): SkillType {
  switch (object) {
    case 0:
    case "ST_NONE":
      return SkillType.ST_NONE;
    case 1:
    case "ST_STATE":
      return SkillType.ST_STATE;
    case 2:
    case "ST_STATE_LIFE":
      return SkillType.ST_STATE_LIFE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SkillType.UNRECOGNIZED;
  }
}

export function skillTypeToJSON(object: SkillType): string {
  switch (object) {
    case SkillType.ST_NONE:
      return "ST_NONE";
    case SkillType.ST_STATE:
      return "ST_STATE";
    case SkillType.ST_STATE_LIFE:
      return "ST_STATE_LIFE";
    case SkillType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Source {
  SRC_UNKNOWN = 0,
  SRC_ENROLLHERE = 1,
  SRC_DIALER = 2,
  SRC_IMPORT_FILE = 3,
  SRC_IMPORT_CUSTOM = 4,
  UNRECOGNIZED = -1,
}

export function sourceFromJSON(object: any): Source {
  switch (object) {
    case 0:
    case "SRC_UNKNOWN":
      return Source.SRC_UNKNOWN;
    case 1:
    case "SRC_ENROLLHERE":
      return Source.SRC_ENROLLHERE;
    case 2:
    case "SRC_DIALER":
      return Source.SRC_DIALER;
    case 3:
    case "SRC_IMPORT_FILE":
      return Source.SRC_IMPORT_FILE;
    case 4:
    case "SRC_IMPORT_CUSTOM":
      return Source.SRC_IMPORT_CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Source.UNRECOGNIZED;
  }
}

export function sourceToJSON(object: Source): string {
  switch (object) {
    case Source.SRC_UNKNOWN:
      return "SRC_UNKNOWN";
    case Source.SRC_ENROLLHERE:
      return "SRC_ENROLLHERE";
    case Source.SRC_DIALER:
      return "SRC_DIALER";
    case Source.SRC_IMPORT_FILE:
      return "SRC_IMPORT_FILE";
    case Source.SRC_IMPORT_CUSTOM:
      return "SRC_IMPORT_CUSTOM";
    case Source.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SortDirection {
  SD_UNKNOWN = 0,
  SD_ASC = 1,
  SD_DESC = 2,
  UNRECOGNIZED = -1,
}

export function sortDirectionFromJSON(object: any): SortDirection {
  switch (object) {
    case 0:
    case "SD_UNKNOWN":
      return SortDirection.SD_UNKNOWN;
    case 1:
    case "SD_ASC":
      return SortDirection.SD_ASC;
    case 2:
    case "SD_DESC":
      return SortDirection.SD_DESC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SortDirection.UNRECOGNIZED;
  }
}

export function sortDirectionToJSON(object: SortDirection): string {
  switch (object) {
    case SortDirection.SD_UNKNOWN:
      return "SD_UNKNOWN";
    case SortDirection.SD_ASC:
      return "SD_ASC";
    case SortDirection.SD_DESC:
      return "SD_DESC";
    case SortDirection.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Type {
  TYPE_UNKNOWN = 0,
  T_AGENT = 1,
  T_COMMISSION = 2,
  T_CONSUMER = 3,
  T_DIALER_CALL = 4,
  T_DIALER_LEAD = 5,
  T_ENROLLMENT = 6,
  T_LEAD_DISTRIBUTION = 7,
  T_LEAD_SALE = 8,
  T_POLICY_UPDATE = 9,
  T_PAYOUT = 10,
  UNRECOGNIZED = -1,
}

export function typeFromJSON(object: any): Type {
  switch (object) {
    case 0:
    case "TYPE_UNKNOWN":
      return Type.TYPE_UNKNOWN;
    case 1:
    case "T_AGENT":
      return Type.T_AGENT;
    case 2:
    case "T_COMMISSION":
      return Type.T_COMMISSION;
    case 3:
    case "T_CONSUMER":
      return Type.T_CONSUMER;
    case 4:
    case "T_DIALER_CALL":
      return Type.T_DIALER_CALL;
    case 5:
    case "T_DIALER_LEAD":
      return Type.T_DIALER_LEAD;
    case 6:
    case "T_ENROLLMENT":
      return Type.T_ENROLLMENT;
    case 7:
    case "T_LEAD_DISTRIBUTION":
      return Type.T_LEAD_DISTRIBUTION;
    case 8:
    case "T_LEAD_SALE":
      return Type.T_LEAD_SALE;
    case 9:
    case "T_POLICY_UPDATE":
      return Type.T_POLICY_UPDATE;
    case 10:
    case "T_PAYOUT":
      return Type.T_PAYOUT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Type.UNRECOGNIZED;
  }
}

export function typeToJSON(object: Type): string {
  switch (object) {
    case Type.TYPE_UNKNOWN:
      return "TYPE_UNKNOWN";
    case Type.T_AGENT:
      return "T_AGENT";
    case Type.T_COMMISSION:
      return "T_COMMISSION";
    case Type.T_CONSUMER:
      return "T_CONSUMER";
    case Type.T_DIALER_CALL:
      return "T_DIALER_CALL";
    case Type.T_DIALER_LEAD:
      return "T_DIALER_LEAD";
    case Type.T_ENROLLMENT:
      return "T_ENROLLMENT";
    case Type.T_LEAD_DISTRIBUTION:
      return "T_LEAD_DISTRIBUTION";
    case Type.T_LEAD_SALE:
      return "T_LEAD_SALE";
    case Type.T_POLICY_UPDATE:
      return "T_POLICY_UPDATE";
    case Type.T_PAYOUT:
      return "T_PAYOUT";
    case Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum HttpRequestMethod {
  HTTP_UNKNOWN = 0,
  HTTP_GET = 1,
  HTTP_POST = 2,
  HTTP_PATCH = 3,
  HTTP_PUT = 4,
  HTTP_DELETE = 5,
  UNRECOGNIZED = -1,
}

export function httpRequestMethodFromJSON(object: any): HttpRequestMethod {
  switch (object) {
    case 0:
    case "HTTP_UNKNOWN":
      return HttpRequestMethod.HTTP_UNKNOWN;
    case 1:
    case "HTTP_GET":
      return HttpRequestMethod.HTTP_GET;
    case 2:
    case "HTTP_POST":
      return HttpRequestMethod.HTTP_POST;
    case 3:
    case "HTTP_PATCH":
      return HttpRequestMethod.HTTP_PATCH;
    case 4:
    case "HTTP_PUT":
      return HttpRequestMethod.HTTP_PUT;
    case 5:
    case "HTTP_DELETE":
      return HttpRequestMethod.HTTP_DELETE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HttpRequestMethod.UNRECOGNIZED;
  }
}

export function httpRequestMethodToJSON(object: HttpRequestMethod): string {
  switch (object) {
    case HttpRequestMethod.HTTP_UNKNOWN:
      return "HTTP_UNKNOWN";
    case HttpRequestMethod.HTTP_GET:
      return "HTTP_GET";
    case HttpRequestMethod.HTTP_POST:
      return "HTTP_POST";
    case HttpRequestMethod.HTTP_PATCH:
      return "HTTP_PATCH";
    case HttpRequestMethod.HTTP_PUT:
      return "HTTP_PUT";
    case HttpRequestMethod.HTTP_DELETE:
      return "HTTP_DELETE";
    case HttpRequestMethod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EnrollmentSource {
  ES_UNKNOWN = 0,
  ES_SUNFIRE = 1,
  ES_CONNEXURE = 2,
  ES_OTHER = 99,
  UNRECOGNIZED = -1,
}

export function enrollmentSourceFromJSON(object: any): EnrollmentSource {
  switch (object) {
    case 0:
    case "ES_UNKNOWN":
      return EnrollmentSource.ES_UNKNOWN;
    case 1:
    case "ES_SUNFIRE":
      return EnrollmentSource.ES_SUNFIRE;
    case 2:
    case "ES_CONNEXURE":
      return EnrollmentSource.ES_CONNEXURE;
    case 99:
    case "ES_OTHER":
      return EnrollmentSource.ES_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EnrollmentSource.UNRECOGNIZED;
  }
}

export function enrollmentSourceToJSON(object: EnrollmentSource): string {
  switch (object) {
    case EnrollmentSource.ES_UNKNOWN:
      return "ES_UNKNOWN";
    case EnrollmentSource.ES_SUNFIRE:
      return "ES_SUNFIRE";
    case EnrollmentSource.ES_CONNEXURE:
      return "ES_CONNEXURE";
    case EnrollmentSource.ES_OTHER:
      return "ES_OTHER";
    case EnrollmentSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MathOperation {
  MO_UNKNOWN = 0,
  MO_EQUAL = 1,
  MO_NOT_EQUAL = 2,
  MO_GREATER_THAN = 3,
  MO_LESS_THAN = 4,
  MO_GREATER_THAN_OR_EQUAL = 5,
  MO_LESS_THAN_OR_EQUAL = 6,
  UNRECOGNIZED = -1,
}

export function mathOperationFromJSON(object: any): MathOperation {
  switch (object) {
    case 0:
    case "MO_UNKNOWN":
      return MathOperation.MO_UNKNOWN;
    case 1:
    case "MO_EQUAL":
      return MathOperation.MO_EQUAL;
    case 2:
    case "MO_NOT_EQUAL":
      return MathOperation.MO_NOT_EQUAL;
    case 3:
    case "MO_GREATER_THAN":
      return MathOperation.MO_GREATER_THAN;
    case 4:
    case "MO_LESS_THAN":
      return MathOperation.MO_LESS_THAN;
    case 5:
    case "MO_GREATER_THAN_OR_EQUAL":
      return MathOperation.MO_GREATER_THAN_OR_EQUAL;
    case 6:
    case "MO_LESS_THAN_OR_EQUAL":
      return MathOperation.MO_LESS_THAN_OR_EQUAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MathOperation.UNRECOGNIZED;
  }
}

export function mathOperationToJSON(object: MathOperation): string {
  switch (object) {
    case MathOperation.MO_UNKNOWN:
      return "MO_UNKNOWN";
    case MathOperation.MO_EQUAL:
      return "MO_EQUAL";
    case MathOperation.MO_NOT_EQUAL:
      return "MO_NOT_EQUAL";
    case MathOperation.MO_GREATER_THAN:
      return "MO_GREATER_THAN";
    case MathOperation.MO_LESS_THAN:
      return "MO_LESS_THAN";
    case MathOperation.MO_GREATER_THAN_OR_EQUAL:
      return "MO_GREATER_THAN_OR_EQUAL";
    case MathOperation.MO_LESS_THAN_OR_EQUAL:
      return "MO_LESS_THAN_OR_EQUAL";
    case MathOperation.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DigitInputTransform {
  DIT_NONE = 0,
  DIT_UPPERCASE = 1,
  DIT_LOWERCASE = 2,
  UNRECOGNIZED = -1,
}

export function digitInputTransformFromJSON(object: any): DigitInputTransform {
  switch (object) {
    case 0:
    case "DIT_NONE":
      return DigitInputTransform.DIT_NONE;
    case 1:
    case "DIT_UPPERCASE":
      return DigitInputTransform.DIT_UPPERCASE;
    case 2:
    case "DIT_LOWERCASE":
      return DigitInputTransform.DIT_LOWERCASE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DigitInputTransform.UNRECOGNIZED;
  }
}

export function digitInputTransformToJSON(object: DigitInputTransform): string {
  switch (object) {
    case DigitInputTransform.DIT_NONE:
      return "DIT_NONE";
    case DigitInputTransform.DIT_UPPERCASE:
      return "DIT_UPPERCASE";
    case DigitInputTransform.DIT_LOWERCASE:
      return "DIT_LOWERCASE";
    case DigitInputTransform.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum BaseCategory {
  BC_UNKNOWN = 0,
  BC_MEDICARE = 1,
  BC_LIFE = 2,
  BC_HEALTH = 3,
  BC_ANNUITY = 4,
  UNRECOGNIZED = -1,
}

export function baseCategoryFromJSON(object: any): BaseCategory {
  switch (object) {
    case 0:
    case "BC_UNKNOWN":
      return BaseCategory.BC_UNKNOWN;
    case 1:
    case "BC_MEDICARE":
      return BaseCategory.BC_MEDICARE;
    case 2:
    case "BC_LIFE":
      return BaseCategory.BC_LIFE;
    case 3:
    case "BC_HEALTH":
      return BaseCategory.BC_HEALTH;
    case 4:
    case "BC_ANNUITY":
      return BaseCategory.BC_ANNUITY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BaseCategory.UNRECOGNIZED;
  }
}

export function baseCategoryToJSON(object: BaseCategory): string {
  switch (object) {
    case BaseCategory.BC_UNKNOWN:
      return "BC_UNKNOWN";
    case BaseCategory.BC_MEDICARE:
      return "BC_MEDICARE";
    case BaseCategory.BC_LIFE:
      return "BC_LIFE";
    case BaseCategory.BC_HEALTH:
      return "BC_HEALTH";
    case BaseCategory.BC_ANNUITY:
      return "BC_ANNUITY";
    case BaseCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Address {
  line1?: string | undefined;
  line2?: string | undefined;
  zip?: string | undefined;
  city?: string | undefined;
  county?: string | undefined;
  state?: string | undefined;
  fips?: string | undefined;
  lat?: number | undefined;
  lng?: number | undefined;
}

export interface IconListItem {
  id: string;
  title?: string | undefined;
  subTitle?: string | undefined;
  iconClass?: string | undefined;
  active?: boolean | undefined;
  routerLink?: string | undefined;
  color?: string | undefined;
  category?: number | undefined;
}

export interface KeyValueString {
  key: string;
  value: string;
}

export interface LogMetadata {
  callControlId?: string | undefined;
  callId?: string | undefined;
  startFunctionTime?: number | undefined;
  requestType?: string | undefined;
}

export interface DateContainer {
  date?: Date | undefined;
  timestamp?: number | undefined;
  day?: number | undefined;
  month?: number | undefined;
  year?: number | undefined;
}

export interface TierFilterListElement {
  indexKey?: string | undefined;
  id?: string | undefined;
  userType?: string | undefined;
  viewerUserId?: string | undefined;
  tier?: string | undefined;
}

export interface Features {
  canSellOnMarketplace?: boolean | undefined;
  consumersView?: boolean | undefined;
  agencyReportView?: boolean | undefined;
  commissions?: boolean | undefined;
  commissionFeatures?: FeaturesCommissions | undefined;
  dialer?: boolean | undefined;
  dialerFeatures?: FeaturesDialer | undefined;
  reportingFeatures?: FeaturesReporting | undefined;
  useV1EnrollmentReport?: boolean | undefined;
}

export interface FeaturesCommissions {
  showPayoutReport?: boolean | undefined;
  showEnrollmentDetails?: boolean | undefined;
}

export interface FeaturesDialer {
  publisherDashboard?: boolean | undefined;
}

export interface FeaturesReporting {
  custom?: FeautresCustomContainer | undefined;
}

export interface FeautresCustomContainer {
  providers: { [key: string]: boolean };
}

export interface FeautresCustomContainer_ProvidersEntry {
  key: string;
  value: boolean;
}

export interface UserDisabled {
  startDate?: DateContainer | undefined;
}

export interface FieldUpdateContainer {
  updates: { [key: string]: FieldUpdate };
}

export interface FieldUpdateContainer_UpdatesEntry {
  key: string;
  value: FieldUpdate | undefined;
}

export interface FieldUpdate {
  value?: string | undefined;
  updatedAt?:
    | number
    | undefined;
  /** source */
  updatedBy?: string | undefined;
}

export interface IdentifierContainer {
  bySource: { [key: string]: IdentifierRepeatable };
  list?: IdentifierRepeatable | undefined;
  identifierGroupId?: string | undefined;
  internalIds?: IdentifierContainer_InternalIds | undefined;
  isTemp?: boolean | undefined;
}

export interface IdentifierContainer_BySourceEntry {
  key: string;
  value: IdentifierRepeatable | undefined;
}

export interface IdentifierContainer_InternalIds {
  /** repeated string enrollmentIds = 1; */
  enrollmentId?: string | undefined;
  commissionIds: string[];
}

export interface IdentifierRepeatable {
  ids: string[];
}

export interface PolicyUpdateContainer {
  /** status by policy id */
  statusUpdateByUpdater: { [key: string]: string };
  isTempEntity?: boolean | undefined;
}

export interface PolicyUpdateContainer_StatusUpdateByUpdaterEntry {
  key: string;
  value: string;
}

export interface TagsContainer {
  /** tag by policy id */
  tagByUpdater: { [key: string]: string };
  /** policyUpdateId */
  lastUpdater?: string | undefined;
  currentTags: string[];
  historicTags: string[];
}

export interface TagsContainer_TagByUpdaterEntry {
  key: string;
  value: string;
}

export interface PermanentTagsContainer {
  /** map<string, bool> byId = 1; */
  list: string[];
}

export interface CommissionCycle {
  id?: string | undefined;
  name?: string | undefined;
  cycleStartDate?: DateContainer | undefined;
  cycleEndDate?: DateContainer | undefined;
  userId?: string | undefined;
  closedAt?: DateContainer | undefined;
  closedBy?: UserIdContainer | undefined;
  agency?: UserIdContainer | undefined;
  calculatedAt?: DateContainer | undefined;
  active?: boolean | undefined;
}

export interface Skill {
  id?: string | undefined;
  name?: string | undefined;
  active?: boolean | undefined;
  values: string[];
  type?: SkillType | undefined;
  agency?: UserIdContainer | undefined;
}

export interface SkillUser {
  id?: string | undefined;
  type?: SkillType | undefined;
  map: { [key: string]: boolean };
}

export interface SkillUser_MapEntry {
  key: string;
  value: boolean;
}

export interface ReferenceContainer {
  id?: string | undefined;
  name?: string | undefined;
  isCustom?: boolean | undefined;
}

export interface UserIdContainer {
  id?: string | undefined;
}

export interface DialerWebRtc {
  user?: string | undefined;
  pass?: string | undefined;
  connection?: string | undefined;
}

export interface DialerSettings {
  callCap?: DialerCallCap | undefined;
}

export interface DialerCallCap {
  enabled?: boolean | undefined;
  allMustApply?: boolean | undefined;
  todayCalls?: number | undefined;
  todayCallsBillable?: number | undefined;
}

export interface SkillListContainer {
  byId: { [key: string]: SkillUser };
  list: string[];
  listByType: string[];
}

export interface SkillListContainer_ByIdEntry {
  key: string;
  value: SkillUser | undefined;
}

export interface StringListContainer {
  list: string[];
}

export interface EnrollmentCodeContainer {
  byCall: { [key: string]: EnrollmentCode };
}

export interface EnrollmentCodeContainer_ByCallEntry {
  key: string;
  value: EnrollmentCode | undefined;
}

export interface EnrollmentCode {
  code?: string | undefined;
  source?: EnrollmentSource | undefined;
  expiresAt?: number | undefined;
  note?: string | undefined;
  skip?: boolean | undefined;
}

export interface DigitInputConfiguration {
  digits?: number | undefined;
  transform?: DigitInputTransform | undefined;
  allowNumbers?: boolean | undefined;
  allowSpecialCharacters?: boolean | undefined;
  allowLetters?: boolean | undefined;
}

function createBaseAddress(): Address {
  return {
    line1: undefined,
    line2: undefined,
    zip: undefined,
    city: undefined,
    county: undefined,
    state: undefined,
    fips: undefined,
    lat: undefined,
    lng: undefined,
  };
}

export const Address = {
  encode(message: Address, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.line1 !== undefined) {
      writer.uint32(10).string(message.line1);
    }
    if (message.line2 !== undefined) {
      writer.uint32(18).string(message.line2);
    }
    if (message.zip !== undefined) {
      writer.uint32(26).string(message.zip);
    }
    if (message.city !== undefined) {
      writer.uint32(34).string(message.city);
    }
    if (message.county !== undefined) {
      writer.uint32(42).string(message.county);
    }
    if (message.state !== undefined) {
      writer.uint32(50).string(message.state);
    }
    if (message.fips !== undefined) {
      writer.uint32(58).string(message.fips);
    }
    if (message.lat !== undefined) {
      writer.uint32(65).double(message.lat);
    }
    if (message.lng !== undefined) {
      writer.uint32(73).double(message.lng);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Address {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.line1 = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.line2 = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.zip = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.city = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.county = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.state = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fips = reader.string();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.lat = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.lng = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Address {
    return {
      line1: isSet(object.line1) ? String(object.line1) : undefined,
      line2: isSet(object.line2) ? String(object.line2) : undefined,
      zip: isSet(object.zip) ? String(object.zip) : undefined,
      city: isSet(object.city) ? String(object.city) : undefined,
      county: isSet(object.county) ? String(object.county) : undefined,
      state: isSet(object.state) ? String(object.state) : undefined,
      fips: isSet(object.fips) ? String(object.fips) : undefined,
      lat: isSet(object.lat) ? Number(object.lat) : undefined,
      lng: isSet(object.lng) ? Number(object.lng) : undefined,
    };
  },

  toJSON(message: Address): unknown {
    const obj: any = {};
    message.line1 !== undefined && (obj.line1 = message.line1);
    message.line2 !== undefined && (obj.line2 = message.line2);
    message.zip !== undefined && (obj.zip = message.zip);
    message.city !== undefined && (obj.city = message.city);
    message.county !== undefined && (obj.county = message.county);
    message.state !== undefined && (obj.state = message.state);
    message.fips !== undefined && (obj.fips = message.fips);
    message.lat !== undefined && (obj.lat = message.lat);
    message.lng !== undefined && (obj.lng = message.lng);
    return obj;
  },

  create<I extends Exact<DeepPartial<Address>, I>>(base?: I): Address {
    return Address.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Address>, I>>(object: I): Address {
    const message = createBaseAddress();
    message.line1 = object.line1 ?? undefined;
    message.line2 = object.line2 ?? undefined;
    message.zip = object.zip ?? undefined;
    message.city = object.city ?? undefined;
    message.county = object.county ?? undefined;
    message.state = object.state ?? undefined;
    message.fips = object.fips ?? undefined;
    message.lat = object.lat ?? undefined;
    message.lng = object.lng ?? undefined;
    return message;
  },
};

function createBaseIconListItem(): IconListItem {
  return {
    id: "",
    title: undefined,
    subTitle: undefined,
    iconClass: undefined,
    active: undefined,
    routerLink: undefined,
    color: undefined,
    category: undefined,
  };
}

export const IconListItem = {
  encode(message: IconListItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.title !== undefined) {
      writer.uint32(18).string(message.title);
    }
    if (message.subTitle !== undefined) {
      writer.uint32(26).string(message.subTitle);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(34).string(message.iconClass);
    }
    if (message.active !== undefined) {
      writer.uint32(40).bool(message.active);
    }
    if (message.routerLink !== undefined) {
      writer.uint32(50).string(message.routerLink);
    }
    if (message.color !== undefined) {
      writer.uint32(58).string(message.color);
    }
    if (message.category !== undefined) {
      writer.uint32(64).int32(message.category);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IconListItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIconListItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subTitle = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.routerLink = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.color = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.category = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IconListItem {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : undefined,
      subTitle: isSet(object.subTitle) ? String(object.subTitle) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      active: isSet(object.active) ? Boolean(object.active) : undefined,
      routerLink: isSet(object.routerLink) ? String(object.routerLink) : undefined,
      color: isSet(object.color) ? String(object.color) : undefined,
      category: isSet(object.category) ? Number(object.category) : undefined,
    };
  },

  toJSON(message: IconListItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    message.subTitle !== undefined && (obj.subTitle = message.subTitle);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    message.active !== undefined && (obj.active = message.active);
    message.routerLink !== undefined && (obj.routerLink = message.routerLink);
    message.color !== undefined && (obj.color = message.color);
    message.category !== undefined && (obj.category = Math.round(message.category));
    return obj;
  },

  create<I extends Exact<DeepPartial<IconListItem>, I>>(base?: I): IconListItem {
    return IconListItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IconListItem>, I>>(object: I): IconListItem {
    const message = createBaseIconListItem();
    message.id = object.id ?? "";
    message.title = object.title ?? undefined;
    message.subTitle = object.subTitle ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.active = object.active ?? undefined;
    message.routerLink = object.routerLink ?? undefined;
    message.color = object.color ?? undefined;
    message.category = object.category ?? undefined;
    return message;
  },
};

function createBaseKeyValueString(): KeyValueString {
  return { key: "", value: "" };
}

export const KeyValueString = {
  encode(message: KeyValueString, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KeyValueString {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKeyValueString();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KeyValueString {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: KeyValueString): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<KeyValueString>, I>>(base?: I): KeyValueString {
    return KeyValueString.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KeyValueString>, I>>(object: I): KeyValueString {
    const message = createBaseKeyValueString();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseLogMetadata(): LogMetadata {
  return { callControlId: undefined, callId: undefined, startFunctionTime: undefined, requestType: undefined };
}

export const LogMetadata = {
  encode(message: LogMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.callControlId !== undefined) {
      writer.uint32(10).string(message.callControlId);
    }
    if (message.callId !== undefined) {
      writer.uint32(18).string(message.callId);
    }
    if (message.startFunctionTime !== undefined) {
      writer.uint32(24).int64(message.startFunctionTime);
    }
    if (message.requestType !== undefined) {
      writer.uint32(34).string(message.requestType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.callControlId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.callId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.startFunctionTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.requestType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogMetadata {
    return {
      callControlId: isSet(object.callControlId) ? String(object.callControlId) : undefined,
      callId: isSet(object.callId) ? String(object.callId) : undefined,
      startFunctionTime: isSet(object.startFunctionTime) ? Number(object.startFunctionTime) : undefined,
      requestType: isSet(object.requestType) ? String(object.requestType) : undefined,
    };
  },

  toJSON(message: LogMetadata): unknown {
    const obj: any = {};
    message.callControlId !== undefined && (obj.callControlId = message.callControlId);
    message.callId !== undefined && (obj.callId = message.callId);
    message.startFunctionTime !== undefined && (obj.startFunctionTime = Math.round(message.startFunctionTime));
    message.requestType !== undefined && (obj.requestType = message.requestType);
    return obj;
  },

  create<I extends Exact<DeepPartial<LogMetadata>, I>>(base?: I): LogMetadata {
    return LogMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LogMetadata>, I>>(object: I): LogMetadata {
    const message = createBaseLogMetadata();
    message.callControlId = object.callControlId ?? undefined;
    message.callId = object.callId ?? undefined;
    message.startFunctionTime = object.startFunctionTime ?? undefined;
    message.requestType = object.requestType ?? undefined;
    return message;
  },
};

function createBaseDateContainer(): DateContainer {
  return { date: undefined, timestamp: undefined, day: undefined, month: undefined, year: undefined };
}

export const DateContainer = {
  encode(message: DateContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(10).fork()).ldelim();
    }
    if (message.timestamp !== undefined) {
      writer.uint32(16).int64(message.timestamp);
    }
    if (message.day !== undefined) {
      writer.uint32(24).int32(message.day);
    }
    if (message.month !== undefined) {
      writer.uint32(32).int32(message.month);
    }
    if (message.year !== undefined) {
      writer.uint32(40).int32(message.year);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DateContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDateContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestamp = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.day = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.month = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.year = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DateContainer {
    return {
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      timestamp: isSet(object.timestamp) ? Number(object.timestamp) : undefined,
      day: isSet(object.day) ? Number(object.day) : undefined,
      month: isSet(object.month) ? Number(object.month) : undefined,
      year: isSet(object.year) ? Number(object.year) : undefined,
    };
  },

  toJSON(message: DateContainer): unknown {
    const obj: any = {};
    message.date !== undefined && (obj.date = message.date.toISOString());
    message.timestamp !== undefined && (obj.timestamp = Math.round(message.timestamp));
    message.day !== undefined && (obj.day = Math.round(message.day));
    message.month !== undefined && (obj.month = Math.round(message.month));
    message.year !== undefined && (obj.year = Math.round(message.year));
    return obj;
  },

  create<I extends Exact<DeepPartial<DateContainer>, I>>(base?: I): DateContainer {
    return DateContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DateContainer>, I>>(object: I): DateContainer {
    const message = createBaseDateContainer();
    message.date = object.date ?? undefined;
    message.timestamp = object.timestamp ?? undefined;
    message.day = object.day ?? undefined;
    message.month = object.month ?? undefined;
    message.year = object.year ?? undefined;
    return message;
  },
};

function createBaseTierFilterListElement(): TierFilterListElement {
  return { indexKey: undefined, id: undefined, userType: undefined, viewerUserId: undefined, tier: undefined };
}

export const TierFilterListElement = {
  encode(message: TierFilterListElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.indexKey !== undefined) {
      writer.uint32(10).string(message.indexKey);
    }
    if (message.id !== undefined) {
      writer.uint32(18).string(message.id);
    }
    if (message.userType !== undefined) {
      writer.uint32(26).string(message.userType);
    }
    if (message.viewerUserId !== undefined) {
      writer.uint32(34).string(message.viewerUserId);
    }
    if (message.tier !== undefined) {
      writer.uint32(42).string(message.tier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TierFilterListElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTierFilterListElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.indexKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userType = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.viewerUserId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TierFilterListElement {
    return {
      indexKey: isSet(object.indexKey) ? String(object.indexKey) : undefined,
      id: isSet(object.id) ? String(object.id) : undefined,
      userType: isSet(object.userType) ? String(object.userType) : undefined,
      viewerUserId: isSet(object.viewerUserId) ? String(object.viewerUserId) : undefined,
      tier: isSet(object.tier) ? String(object.tier) : undefined,
    };
  },

  toJSON(message: TierFilterListElement): unknown {
    const obj: any = {};
    message.indexKey !== undefined && (obj.indexKey = message.indexKey);
    message.id !== undefined && (obj.id = message.id);
    message.userType !== undefined && (obj.userType = message.userType);
    message.viewerUserId !== undefined && (obj.viewerUserId = message.viewerUserId);
    message.tier !== undefined && (obj.tier = message.tier);
    return obj;
  },

  create<I extends Exact<DeepPartial<TierFilterListElement>, I>>(base?: I): TierFilterListElement {
    return TierFilterListElement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TierFilterListElement>, I>>(object: I): TierFilterListElement {
    const message = createBaseTierFilterListElement();
    message.indexKey = object.indexKey ?? undefined;
    message.id = object.id ?? undefined;
    message.userType = object.userType ?? undefined;
    message.viewerUserId = object.viewerUserId ?? undefined;
    message.tier = object.tier ?? undefined;
    return message;
  },
};

function createBaseFeatures(): Features {
  return {
    canSellOnMarketplace: undefined,
    consumersView: undefined,
    agencyReportView: undefined,
    commissions: undefined,
    commissionFeatures: undefined,
    dialer: undefined,
    dialerFeatures: undefined,
    reportingFeatures: undefined,
    useV1EnrollmentReport: undefined,
  };
}

export const Features = {
  encode(message: Features, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.canSellOnMarketplace !== undefined) {
      writer.uint32(8).bool(message.canSellOnMarketplace);
    }
    if (message.consumersView !== undefined) {
      writer.uint32(16).bool(message.consumersView);
    }
    if (message.agencyReportView !== undefined) {
      writer.uint32(24).bool(message.agencyReportView);
    }
    if (message.commissions !== undefined) {
      writer.uint32(32).bool(message.commissions);
    }
    if (message.commissionFeatures !== undefined) {
      FeaturesCommissions.encode(message.commissionFeatures, writer.uint32(42).fork()).ldelim();
    }
    if (message.dialer !== undefined) {
      writer.uint32(48).bool(message.dialer);
    }
    if (message.dialerFeatures !== undefined) {
      FeaturesDialer.encode(message.dialerFeatures, writer.uint32(58).fork()).ldelim();
    }
    if (message.reportingFeatures !== undefined) {
      FeaturesReporting.encode(message.reportingFeatures, writer.uint32(66).fork()).ldelim();
    }
    if (message.useV1EnrollmentReport !== undefined) {
      writer.uint32(72).bool(message.useV1EnrollmentReport);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Features {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeatures();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.canSellOnMarketplace = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.consumersView = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.agencyReportView = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.commissions = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.commissionFeatures = FeaturesCommissions.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.dialer = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.dialerFeatures = FeaturesDialer.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.reportingFeatures = FeaturesReporting.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.useV1EnrollmentReport = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Features {
    return {
      canSellOnMarketplace: isSet(object.canSellOnMarketplace) ? Boolean(object.canSellOnMarketplace) : undefined,
      consumersView: isSet(object.consumersView) ? Boolean(object.consumersView) : undefined,
      agencyReportView: isSet(object.agencyReportView) ? Boolean(object.agencyReportView) : undefined,
      commissions: isSet(object.commissions) ? Boolean(object.commissions) : undefined,
      commissionFeatures: isSet(object.commissionFeatures)
        ? FeaturesCommissions.fromJSON(object.commissionFeatures)
        : undefined,
      dialer: isSet(object.dialer) ? Boolean(object.dialer) : undefined,
      dialerFeatures: isSet(object.dialerFeatures) ? FeaturesDialer.fromJSON(object.dialerFeatures) : undefined,
      reportingFeatures: isSet(object.reportingFeatures)
        ? FeaturesReporting.fromJSON(object.reportingFeatures)
        : undefined,
      useV1EnrollmentReport: isSet(object.useV1EnrollmentReport) ? Boolean(object.useV1EnrollmentReport) : undefined,
    };
  },

  toJSON(message: Features): unknown {
    const obj: any = {};
    message.canSellOnMarketplace !== undefined && (obj.canSellOnMarketplace = message.canSellOnMarketplace);
    message.consumersView !== undefined && (obj.consumersView = message.consumersView);
    message.agencyReportView !== undefined && (obj.agencyReportView = message.agencyReportView);
    message.commissions !== undefined && (obj.commissions = message.commissions);
    message.commissionFeatures !== undefined && (obj.commissionFeatures = message.commissionFeatures
      ? FeaturesCommissions.toJSON(message.commissionFeatures)
      : undefined);
    message.dialer !== undefined && (obj.dialer = message.dialer);
    message.dialerFeatures !== undefined &&
      (obj.dialerFeatures = message.dialerFeatures ? FeaturesDialer.toJSON(message.dialerFeatures) : undefined);
    message.reportingFeatures !== undefined && (obj.reportingFeatures = message.reportingFeatures
      ? FeaturesReporting.toJSON(message.reportingFeatures)
      : undefined);
    message.useV1EnrollmentReport !== undefined && (obj.useV1EnrollmentReport = message.useV1EnrollmentReport);
    return obj;
  },

  create<I extends Exact<DeepPartial<Features>, I>>(base?: I): Features {
    return Features.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Features>, I>>(object: I): Features {
    const message = createBaseFeatures();
    message.canSellOnMarketplace = object.canSellOnMarketplace ?? undefined;
    message.consumersView = object.consumersView ?? undefined;
    message.agencyReportView = object.agencyReportView ?? undefined;
    message.commissions = object.commissions ?? undefined;
    message.commissionFeatures = (object.commissionFeatures !== undefined && object.commissionFeatures !== null)
      ? FeaturesCommissions.fromPartial(object.commissionFeatures)
      : undefined;
    message.dialer = object.dialer ?? undefined;
    message.dialerFeatures = (object.dialerFeatures !== undefined && object.dialerFeatures !== null)
      ? FeaturesDialer.fromPartial(object.dialerFeatures)
      : undefined;
    message.reportingFeatures = (object.reportingFeatures !== undefined && object.reportingFeatures !== null)
      ? FeaturesReporting.fromPartial(object.reportingFeatures)
      : undefined;
    message.useV1EnrollmentReport = object.useV1EnrollmentReport ?? undefined;
    return message;
  },
};

function createBaseFeaturesCommissions(): FeaturesCommissions {
  return { showPayoutReport: undefined, showEnrollmentDetails: undefined };
}

export const FeaturesCommissions = {
  encode(message: FeaturesCommissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.showPayoutReport !== undefined) {
      writer.uint32(8).bool(message.showPayoutReport);
    }
    if (message.showEnrollmentDetails !== undefined) {
      writer.uint32(16).bool(message.showEnrollmentDetails);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeaturesCommissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeaturesCommissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.showPayoutReport = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.showEnrollmentDetails = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeaturesCommissions {
    return {
      showPayoutReport: isSet(object.showPayoutReport) ? Boolean(object.showPayoutReport) : undefined,
      showEnrollmentDetails: isSet(object.showEnrollmentDetails) ? Boolean(object.showEnrollmentDetails) : undefined,
    };
  },

  toJSON(message: FeaturesCommissions): unknown {
    const obj: any = {};
    message.showPayoutReport !== undefined && (obj.showPayoutReport = message.showPayoutReport);
    message.showEnrollmentDetails !== undefined && (obj.showEnrollmentDetails = message.showEnrollmentDetails);
    return obj;
  },

  create<I extends Exact<DeepPartial<FeaturesCommissions>, I>>(base?: I): FeaturesCommissions {
    return FeaturesCommissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FeaturesCommissions>, I>>(object: I): FeaturesCommissions {
    const message = createBaseFeaturesCommissions();
    message.showPayoutReport = object.showPayoutReport ?? undefined;
    message.showEnrollmentDetails = object.showEnrollmentDetails ?? undefined;
    return message;
  },
};

function createBaseFeaturesDialer(): FeaturesDialer {
  return { publisherDashboard: undefined };
}

export const FeaturesDialer = {
  encode(message: FeaturesDialer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.publisherDashboard !== undefined) {
      writer.uint32(8).bool(message.publisherDashboard);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeaturesDialer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeaturesDialer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.publisherDashboard = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeaturesDialer {
    return { publisherDashboard: isSet(object.publisherDashboard) ? Boolean(object.publisherDashboard) : undefined };
  },

  toJSON(message: FeaturesDialer): unknown {
    const obj: any = {};
    message.publisherDashboard !== undefined && (obj.publisherDashboard = message.publisherDashboard);
    return obj;
  },

  create<I extends Exact<DeepPartial<FeaturesDialer>, I>>(base?: I): FeaturesDialer {
    return FeaturesDialer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FeaturesDialer>, I>>(object: I): FeaturesDialer {
    const message = createBaseFeaturesDialer();
    message.publisherDashboard = object.publisherDashboard ?? undefined;
    return message;
  },
};

function createBaseFeaturesReporting(): FeaturesReporting {
  return { custom: undefined };
}

export const FeaturesReporting = {
  encode(message: FeaturesReporting, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.custom !== undefined) {
      FeautresCustomContainer.encode(message.custom, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeaturesReporting {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeaturesReporting();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.custom = FeautresCustomContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeaturesReporting {
    return { custom: isSet(object.custom) ? FeautresCustomContainer.fromJSON(object.custom) : undefined };
  },

  toJSON(message: FeaturesReporting): unknown {
    const obj: any = {};
    message.custom !== undefined &&
      (obj.custom = message.custom ? FeautresCustomContainer.toJSON(message.custom) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<FeaturesReporting>, I>>(base?: I): FeaturesReporting {
    return FeaturesReporting.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FeaturesReporting>, I>>(object: I): FeaturesReporting {
    const message = createBaseFeaturesReporting();
    message.custom = (object.custom !== undefined && object.custom !== null)
      ? FeautresCustomContainer.fromPartial(object.custom)
      : undefined;
    return message;
  },
};

function createBaseFeautresCustomContainer(): FeautresCustomContainer {
  return { providers: {} };
}

export const FeautresCustomContainer = {
  encode(message: FeautresCustomContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.providers).forEach(([key, value]) => {
      FeautresCustomContainer_ProvidersEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeautresCustomContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeautresCustomContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = FeautresCustomContainer_ProvidersEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.providers[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeautresCustomContainer {
    return {
      providers: isObject(object.providers)
        ? Object.entries(object.providers).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
          acc[key] = Boolean(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: FeautresCustomContainer): unknown {
    const obj: any = {};
    obj.providers = {};
    if (message.providers) {
      Object.entries(message.providers).forEach(([k, v]) => {
        obj.providers[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FeautresCustomContainer>, I>>(base?: I): FeautresCustomContainer {
    return FeautresCustomContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FeautresCustomContainer>, I>>(object: I): FeautresCustomContainer {
    const message = createBaseFeautresCustomContainer();
    message.providers = Object.entries(object.providers ?? {}).reduce<{ [key: string]: boolean }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Boolean(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseFeautresCustomContainer_ProvidersEntry(): FeautresCustomContainer_ProvidersEntry {
  return { key: "", value: false };
}

export const FeautresCustomContainer_ProvidersEntry = {
  encode(message: FeautresCustomContainer_ProvidersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value === true) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeautresCustomContainer_ProvidersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeautresCustomContainer_ProvidersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeautresCustomContainer_ProvidersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Boolean(object.value) : false,
    };
  },

  toJSON(message: FeautresCustomContainer_ProvidersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<FeautresCustomContainer_ProvidersEntry>, I>>(
    base?: I,
  ): FeautresCustomContainer_ProvidersEntry {
    return FeautresCustomContainer_ProvidersEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FeautresCustomContainer_ProvidersEntry>, I>>(
    object: I,
  ): FeautresCustomContainer_ProvidersEntry {
    const message = createBaseFeautresCustomContainer_ProvidersEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseUserDisabled(): UserDisabled {
  return { startDate: undefined };
}

export const UserDisabled = {
  encode(message: UserDisabled, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startDate !== undefined) {
      DateContainer.encode(message.startDate, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDisabled {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDisabled();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startDate = DateContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserDisabled {
    return { startDate: isSet(object.startDate) ? DateContainer.fromJSON(object.startDate) : undefined };
  },

  toJSON(message: UserDisabled): unknown {
    const obj: any = {};
    message.startDate !== undefined &&
      (obj.startDate = message.startDate ? DateContainer.toJSON(message.startDate) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDisabled>, I>>(base?: I): UserDisabled {
    return UserDisabled.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserDisabled>, I>>(object: I): UserDisabled {
    const message = createBaseUserDisabled();
    message.startDate = (object.startDate !== undefined && object.startDate !== null)
      ? DateContainer.fromPartial(object.startDate)
      : undefined;
    return message;
  },
};

function createBaseFieldUpdateContainer(): FieldUpdateContainer {
  return { updates: {} };
}

export const FieldUpdateContainer = {
  encode(message: FieldUpdateContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.updates).forEach(([key, value]) => {
      FieldUpdateContainer_UpdatesEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldUpdateContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldUpdateContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = FieldUpdateContainer_UpdatesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.updates[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldUpdateContainer {
    return {
      updates: isObject(object.updates)
        ? Object.entries(object.updates).reduce<{ [key: string]: FieldUpdate }>((acc, [key, value]) => {
          acc[key] = FieldUpdate.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: FieldUpdateContainer): unknown {
    const obj: any = {};
    obj.updates = {};
    if (message.updates) {
      Object.entries(message.updates).forEach(([k, v]) => {
        obj.updates[k] = FieldUpdate.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldUpdateContainer>, I>>(base?: I): FieldUpdateContainer {
    return FieldUpdateContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FieldUpdateContainer>, I>>(object: I): FieldUpdateContainer {
    const message = createBaseFieldUpdateContainer();
    message.updates = Object.entries(object.updates ?? {}).reduce<{ [key: string]: FieldUpdate }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = FieldUpdate.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseFieldUpdateContainer_UpdatesEntry(): FieldUpdateContainer_UpdatesEntry {
  return { key: "", value: undefined };
}

export const FieldUpdateContainer_UpdatesEntry = {
  encode(message: FieldUpdateContainer_UpdatesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      FieldUpdate.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldUpdateContainer_UpdatesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldUpdateContainer_UpdatesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = FieldUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldUpdateContainer_UpdatesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? FieldUpdate.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FieldUpdateContainer_UpdatesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? FieldUpdate.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldUpdateContainer_UpdatesEntry>, I>>(
    base?: I,
  ): FieldUpdateContainer_UpdatesEntry {
    return FieldUpdateContainer_UpdatesEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FieldUpdateContainer_UpdatesEntry>, I>>(
    object: I,
  ): FieldUpdateContainer_UpdatesEntry {
    const message = createBaseFieldUpdateContainer_UpdatesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? FieldUpdate.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFieldUpdate(): FieldUpdate {
  return { value: undefined, updatedAt: undefined, updatedBy: undefined };
}

export const FieldUpdate = {
  encode(message: FieldUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== undefined) {
      writer.uint32(10).string(message.value);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(16).int64(message.updatedAt);
    }
    if (message.updatedBy !== undefined) {
      writer.uint32(26).string(message.updatedBy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedBy = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FieldUpdate {
    return {
      value: isSet(object.value) ? String(object.value) : undefined,
      updatedAt: isSet(object.updatedAt) ? Number(object.updatedAt) : undefined,
      updatedBy: isSet(object.updatedBy) ? String(object.updatedBy) : undefined,
    };
  },

  toJSON(message: FieldUpdate): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    message.updatedAt !== undefined && (obj.updatedAt = Math.round(message.updatedAt));
    message.updatedBy !== undefined && (obj.updatedBy = message.updatedBy);
    return obj;
  },

  create<I extends Exact<DeepPartial<FieldUpdate>, I>>(base?: I): FieldUpdate {
    return FieldUpdate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FieldUpdate>, I>>(object: I): FieldUpdate {
    const message = createBaseFieldUpdate();
    message.value = object.value ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.updatedBy = object.updatedBy ?? undefined;
    return message;
  },
};

function createBaseIdentifierContainer(): IdentifierContainer {
  return { bySource: {}, list: undefined, identifierGroupId: undefined, internalIds: undefined, isTemp: undefined };
}

export const IdentifierContainer = {
  encode(message: IdentifierContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.bySource).forEach(([key, value]) => {
      IdentifierContainer_BySourceEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.list !== undefined) {
      IdentifierRepeatable.encode(message.list, writer.uint32(18).fork()).ldelim();
    }
    if (message.identifierGroupId !== undefined) {
      writer.uint32(26).string(message.identifierGroupId);
    }
    if (message.internalIds !== undefined) {
      IdentifierContainer_InternalIds.encode(message.internalIds, writer.uint32(34).fork()).ldelim();
    }
    if (message.isTemp !== undefined) {
      writer.uint32(40).bool(message.isTemp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IdentifierContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIdentifierContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = IdentifierContainer_BySourceEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.bySource[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.list = IdentifierRepeatable.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.identifierGroupId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.internalIds = IdentifierContainer_InternalIds.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isTemp = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IdentifierContainer {
    return {
      bySource: isObject(object.bySource)
        ? Object.entries(object.bySource).reduce<{ [key: string]: IdentifierRepeatable }>((acc, [key, value]) => {
          acc[key] = IdentifierRepeatable.fromJSON(value);
          return acc;
        }, {})
        : {},
      list: isSet(object.list) ? IdentifierRepeatable.fromJSON(object.list) : undefined,
      identifierGroupId: isSet(object.identifierGroupId) ? String(object.identifierGroupId) : undefined,
      internalIds: isSet(object.internalIds) ? IdentifierContainer_InternalIds.fromJSON(object.internalIds) : undefined,
      isTemp: isSet(object.isTemp) ? Boolean(object.isTemp) : undefined,
    };
  },

  toJSON(message: IdentifierContainer): unknown {
    const obj: any = {};
    obj.bySource = {};
    if (message.bySource) {
      Object.entries(message.bySource).forEach(([k, v]) => {
        obj.bySource[k] = IdentifierRepeatable.toJSON(v);
      });
    }
    message.list !== undefined && (obj.list = message.list ? IdentifierRepeatable.toJSON(message.list) : undefined);
    message.identifierGroupId !== undefined && (obj.identifierGroupId = message.identifierGroupId);
    message.internalIds !== undefined &&
      (obj.internalIds = message.internalIds ? IdentifierContainer_InternalIds.toJSON(message.internalIds) : undefined);
    message.isTemp !== undefined && (obj.isTemp = message.isTemp);
    return obj;
  },

  create<I extends Exact<DeepPartial<IdentifierContainer>, I>>(base?: I): IdentifierContainer {
    return IdentifierContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IdentifierContainer>, I>>(object: I): IdentifierContainer {
    const message = createBaseIdentifierContainer();
    message.bySource = Object.entries(object.bySource ?? {}).reduce<{ [key: string]: IdentifierRepeatable }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = IdentifierRepeatable.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.list = (object.list !== undefined && object.list !== null)
      ? IdentifierRepeatable.fromPartial(object.list)
      : undefined;
    message.identifierGroupId = object.identifierGroupId ?? undefined;
    message.internalIds = (object.internalIds !== undefined && object.internalIds !== null)
      ? IdentifierContainer_InternalIds.fromPartial(object.internalIds)
      : undefined;
    message.isTemp = object.isTemp ?? undefined;
    return message;
  },
};

function createBaseIdentifierContainer_BySourceEntry(): IdentifierContainer_BySourceEntry {
  return { key: "", value: undefined };
}

export const IdentifierContainer_BySourceEntry = {
  encode(message: IdentifierContainer_BySourceEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      IdentifierRepeatable.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IdentifierContainer_BySourceEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIdentifierContainer_BySourceEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = IdentifierRepeatable.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IdentifierContainer_BySourceEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? IdentifierRepeatable.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: IdentifierContainer_BySourceEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? IdentifierRepeatable.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<IdentifierContainer_BySourceEntry>, I>>(
    base?: I,
  ): IdentifierContainer_BySourceEntry {
    return IdentifierContainer_BySourceEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IdentifierContainer_BySourceEntry>, I>>(
    object: I,
  ): IdentifierContainer_BySourceEntry {
    const message = createBaseIdentifierContainer_BySourceEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? IdentifierRepeatable.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseIdentifierContainer_InternalIds(): IdentifierContainer_InternalIds {
  return { enrollmentId: undefined, commissionIds: [] };
}

export const IdentifierContainer_InternalIds = {
  encode(message: IdentifierContainer_InternalIds, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enrollmentId !== undefined) {
      writer.uint32(10).string(message.enrollmentId);
    }
    for (const v of message.commissionIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IdentifierContainer_InternalIds {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIdentifierContainer_InternalIds();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.enrollmentId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.commissionIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IdentifierContainer_InternalIds {
    return {
      enrollmentId: isSet(object.enrollmentId) ? String(object.enrollmentId) : undefined,
      commissionIds: Array.isArray(object?.commissionIds) ? object.commissionIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: IdentifierContainer_InternalIds): unknown {
    const obj: any = {};
    message.enrollmentId !== undefined && (obj.enrollmentId = message.enrollmentId);
    if (message.commissionIds) {
      obj.commissionIds = message.commissionIds.map((e) => e);
    } else {
      obj.commissionIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IdentifierContainer_InternalIds>, I>>(base?: I): IdentifierContainer_InternalIds {
    return IdentifierContainer_InternalIds.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IdentifierContainer_InternalIds>, I>>(
    object: I,
  ): IdentifierContainer_InternalIds {
    const message = createBaseIdentifierContainer_InternalIds();
    message.enrollmentId = object.enrollmentId ?? undefined;
    message.commissionIds = object.commissionIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseIdentifierRepeatable(): IdentifierRepeatable {
  return { ids: [] };
}

export const IdentifierRepeatable = {
  encode(message: IdentifierRepeatable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ids) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IdentifierRepeatable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIdentifierRepeatable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ids.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IdentifierRepeatable {
    return { ids: Array.isArray(object?.ids) ? object.ids.map((e: any) => String(e)) : [] };
  },

  toJSON(message: IdentifierRepeatable): unknown {
    const obj: any = {};
    if (message.ids) {
      obj.ids = message.ids.map((e) => e);
    } else {
      obj.ids = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IdentifierRepeatable>, I>>(base?: I): IdentifierRepeatable {
    return IdentifierRepeatable.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IdentifierRepeatable>, I>>(object: I): IdentifierRepeatable {
    const message = createBaseIdentifierRepeatable();
    message.ids = object.ids?.map((e) => e) || [];
    return message;
  },
};

function createBasePolicyUpdateContainer(): PolicyUpdateContainer {
  return { statusUpdateByUpdater: {}, isTempEntity: undefined };
}

export const PolicyUpdateContainer = {
  encode(message: PolicyUpdateContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.statusUpdateByUpdater).forEach(([key, value]) => {
      PolicyUpdateContainer_StatusUpdateByUpdaterEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    if (message.isTempEntity !== undefined) {
      writer.uint32(16).bool(message.isTempEntity);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PolicyUpdateContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicyUpdateContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = PolicyUpdateContainer_StatusUpdateByUpdaterEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.statusUpdateByUpdater[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isTempEntity = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolicyUpdateContainer {
    return {
      statusUpdateByUpdater: isObject(object.statusUpdateByUpdater)
        ? Object.entries(object.statusUpdateByUpdater).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      isTempEntity: isSet(object.isTempEntity) ? Boolean(object.isTempEntity) : undefined,
    };
  },

  toJSON(message: PolicyUpdateContainer): unknown {
    const obj: any = {};
    obj.statusUpdateByUpdater = {};
    if (message.statusUpdateByUpdater) {
      Object.entries(message.statusUpdateByUpdater).forEach(([k, v]) => {
        obj.statusUpdateByUpdater[k] = v;
      });
    }
    message.isTempEntity !== undefined && (obj.isTempEntity = message.isTempEntity);
    return obj;
  },

  create<I extends Exact<DeepPartial<PolicyUpdateContainer>, I>>(base?: I): PolicyUpdateContainer {
    return PolicyUpdateContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PolicyUpdateContainer>, I>>(object: I): PolicyUpdateContainer {
    const message = createBasePolicyUpdateContainer();
    message.statusUpdateByUpdater = Object.entries(object.statusUpdateByUpdater ?? {}).reduce<
      { [key: string]: string }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.isTempEntity = object.isTempEntity ?? undefined;
    return message;
  },
};

function createBasePolicyUpdateContainer_StatusUpdateByUpdaterEntry(): PolicyUpdateContainer_StatusUpdateByUpdaterEntry {
  return { key: "", value: "" };
}

export const PolicyUpdateContainer_StatusUpdateByUpdaterEntry = {
  encode(
    message: PolicyUpdateContainer_StatusUpdateByUpdaterEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PolicyUpdateContainer_StatusUpdateByUpdaterEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicyUpdateContainer_StatusUpdateByUpdaterEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolicyUpdateContainer_StatusUpdateByUpdaterEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: PolicyUpdateContainer_StatusUpdateByUpdaterEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<PolicyUpdateContainer_StatusUpdateByUpdaterEntry>, I>>(
    base?: I,
  ): PolicyUpdateContainer_StatusUpdateByUpdaterEntry {
    return PolicyUpdateContainer_StatusUpdateByUpdaterEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PolicyUpdateContainer_StatusUpdateByUpdaterEntry>, I>>(
    object: I,
  ): PolicyUpdateContainer_StatusUpdateByUpdaterEntry {
    const message = createBasePolicyUpdateContainer_StatusUpdateByUpdaterEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseTagsContainer(): TagsContainer {
  return { tagByUpdater: {}, lastUpdater: undefined, currentTags: [], historicTags: [] };
}

export const TagsContainer = {
  encode(message: TagsContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.tagByUpdater).forEach(([key, value]) => {
      TagsContainer_TagByUpdaterEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.lastUpdater !== undefined) {
      writer.uint32(18).string(message.lastUpdater);
    }
    for (const v of message.currentTags) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.historicTags) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagsContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagsContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = TagsContainer_TagByUpdaterEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.tagByUpdater[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastUpdater = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.currentTags.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.historicTags.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TagsContainer {
    return {
      tagByUpdater: isObject(object.tagByUpdater)
        ? Object.entries(object.tagByUpdater).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      lastUpdater: isSet(object.lastUpdater) ? String(object.lastUpdater) : undefined,
      currentTags: Array.isArray(object?.currentTags) ? object.currentTags.map((e: any) => String(e)) : [],
      historicTags: Array.isArray(object?.historicTags) ? object.historicTags.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: TagsContainer): unknown {
    const obj: any = {};
    obj.tagByUpdater = {};
    if (message.tagByUpdater) {
      Object.entries(message.tagByUpdater).forEach(([k, v]) => {
        obj.tagByUpdater[k] = v;
      });
    }
    message.lastUpdater !== undefined && (obj.lastUpdater = message.lastUpdater);
    if (message.currentTags) {
      obj.currentTags = message.currentTags.map((e) => e);
    } else {
      obj.currentTags = [];
    }
    if (message.historicTags) {
      obj.historicTags = message.historicTags.map((e) => e);
    } else {
      obj.historicTags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TagsContainer>, I>>(base?: I): TagsContainer {
    return TagsContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TagsContainer>, I>>(object: I): TagsContainer {
    const message = createBaseTagsContainer();
    message.tagByUpdater = Object.entries(object.tagByUpdater ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.lastUpdater = object.lastUpdater ?? undefined;
    message.currentTags = object.currentTags?.map((e) => e) || [];
    message.historicTags = object.historicTags?.map((e) => e) || [];
    return message;
  },
};

function createBaseTagsContainer_TagByUpdaterEntry(): TagsContainer_TagByUpdaterEntry {
  return { key: "", value: "" };
}

export const TagsContainer_TagByUpdaterEntry = {
  encode(message: TagsContainer_TagByUpdaterEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagsContainer_TagByUpdaterEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagsContainer_TagByUpdaterEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TagsContainer_TagByUpdaterEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: TagsContainer_TagByUpdaterEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<TagsContainer_TagByUpdaterEntry>, I>>(base?: I): TagsContainer_TagByUpdaterEntry {
    return TagsContainer_TagByUpdaterEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TagsContainer_TagByUpdaterEntry>, I>>(
    object: I,
  ): TagsContainer_TagByUpdaterEntry {
    const message = createBaseTagsContainer_TagByUpdaterEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBasePermanentTagsContainer(): PermanentTagsContainer {
  return { list: [] };
}

export const PermanentTagsContainer = {
  encode(message: PermanentTagsContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.list) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PermanentTagsContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePermanentTagsContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.list.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PermanentTagsContainer {
    return { list: Array.isArray(object?.list) ? object.list.map((e: any) => String(e)) : [] };
  },

  toJSON(message: PermanentTagsContainer): unknown {
    const obj: any = {};
    if (message.list) {
      obj.list = message.list.map((e) => e);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PermanentTagsContainer>, I>>(base?: I): PermanentTagsContainer {
    return PermanentTagsContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PermanentTagsContainer>, I>>(object: I): PermanentTagsContainer {
    const message = createBasePermanentTagsContainer();
    message.list = object.list?.map((e) => e) || [];
    return message;
  },
};

function createBaseCommissionCycle(): CommissionCycle {
  return {
    id: undefined,
    name: undefined,
    cycleStartDate: undefined,
    cycleEndDate: undefined,
    userId: undefined,
    closedAt: undefined,
    closedBy: undefined,
    agency: undefined,
    calculatedAt: undefined,
    active: undefined,
  };
}

export const CommissionCycle = {
  encode(message: CommissionCycle, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.cycleStartDate !== undefined) {
      DateContainer.encode(message.cycleStartDate, writer.uint32(26).fork()).ldelim();
    }
    if (message.cycleEndDate !== undefined) {
      DateContainer.encode(message.cycleEndDate, writer.uint32(34).fork()).ldelim();
    }
    if (message.userId !== undefined) {
      writer.uint32(42).string(message.userId);
    }
    if (message.closedAt !== undefined) {
      DateContainer.encode(message.closedAt, writer.uint32(50).fork()).ldelim();
    }
    if (message.closedBy !== undefined) {
      UserIdContainer.encode(message.closedBy, writer.uint32(58).fork()).ldelim();
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(66).fork()).ldelim();
    }
    if (message.calculatedAt !== undefined) {
      DateContainer.encode(message.calculatedAt, writer.uint32(74).fork()).ldelim();
    }
    if (message.active !== undefined) {
      writer.uint32(80).bool(message.active);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommissionCycle {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommissionCycle();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cycleStartDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cycleEndDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.closedAt = DateContainer.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.closedBy = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.calculatedAt = DateContainer.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.active = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommissionCycle {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      cycleStartDate: isSet(object.cycleStartDate) ? DateContainer.fromJSON(object.cycleStartDate) : undefined,
      cycleEndDate: isSet(object.cycleEndDate) ? DateContainer.fromJSON(object.cycleEndDate) : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      closedAt: isSet(object.closedAt) ? DateContainer.fromJSON(object.closedAt) : undefined,
      closedBy: isSet(object.closedBy) ? UserIdContainer.fromJSON(object.closedBy) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      calculatedAt: isSet(object.calculatedAt) ? DateContainer.fromJSON(object.calculatedAt) : undefined,
      active: isSet(object.active) ? Boolean(object.active) : undefined,
    };
  },

  toJSON(message: CommissionCycle): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.cycleStartDate !== undefined &&
      (obj.cycleStartDate = message.cycleStartDate ? DateContainer.toJSON(message.cycleStartDate) : undefined);
    message.cycleEndDate !== undefined &&
      (obj.cycleEndDate = message.cycleEndDate ? DateContainer.toJSON(message.cycleEndDate) : undefined);
    message.userId !== undefined && (obj.userId = message.userId);
    message.closedAt !== undefined &&
      (obj.closedAt = message.closedAt ? DateContainer.toJSON(message.closedAt) : undefined);
    message.closedBy !== undefined &&
      (obj.closedBy = message.closedBy ? UserIdContainer.toJSON(message.closedBy) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.calculatedAt !== undefined &&
      (obj.calculatedAt = message.calculatedAt ? DateContainer.toJSON(message.calculatedAt) : undefined);
    message.active !== undefined && (obj.active = message.active);
    return obj;
  },

  create<I extends Exact<DeepPartial<CommissionCycle>, I>>(base?: I): CommissionCycle {
    return CommissionCycle.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CommissionCycle>, I>>(object: I): CommissionCycle {
    const message = createBaseCommissionCycle();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.cycleStartDate = (object.cycleStartDate !== undefined && object.cycleStartDate !== null)
      ? DateContainer.fromPartial(object.cycleStartDate)
      : undefined;
    message.cycleEndDate = (object.cycleEndDate !== undefined && object.cycleEndDate !== null)
      ? DateContainer.fromPartial(object.cycleEndDate)
      : undefined;
    message.userId = object.userId ?? undefined;
    message.closedAt = (object.closedAt !== undefined && object.closedAt !== null)
      ? DateContainer.fromPartial(object.closedAt)
      : undefined;
    message.closedBy = (object.closedBy !== undefined && object.closedBy !== null)
      ? UserIdContainer.fromPartial(object.closedBy)
      : undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.calculatedAt = (object.calculatedAt !== undefined && object.calculatedAt !== null)
      ? DateContainer.fromPartial(object.calculatedAt)
      : undefined;
    message.active = object.active ?? undefined;
    return message;
  },
};

function createBaseSkill(): Skill {
  return { id: undefined, name: undefined, active: undefined, values: [], type: undefined, agency: undefined };
}

export const Skill = {
  encode(message: Skill, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.active !== undefined) {
      writer.uint32(24).bool(message.active);
    }
    for (const v of message.values) {
      writer.uint32(34).string(v!);
    }
    if (message.type !== undefined) {
      writer.uint32(40).int32(message.type);
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Skill {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSkill();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.values.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Skill {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      active: isSet(object.active) ? Boolean(object.active) : undefined,
      values: Array.isArray(object?.values) ? object.values.map((e: any) => String(e)) : [],
      type: isSet(object.type) ? skillTypeFromJSON(object.type) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
    };
  },

  toJSON(message: Skill): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.active !== undefined && (obj.active = message.active);
    if (message.values) {
      obj.values = message.values.map((e) => e);
    } else {
      obj.values = [];
    }
    message.type !== undefined && (obj.type = message.type !== undefined ? skillTypeToJSON(message.type) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Skill>, I>>(base?: I): Skill {
    return Skill.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Skill>, I>>(object: I): Skill {
    const message = createBaseSkill();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.active = object.active ?? undefined;
    message.values = object.values?.map((e) => e) || [];
    message.type = object.type ?? undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    return message;
  },
};

function createBaseSkillUser(): SkillUser {
  return { id: undefined, type: undefined, map: {} };
}

export const SkillUser = {
  encode(message: SkillUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== undefined) {
      writer.uint32(16).int32(message.type);
    }
    Object.entries(message.map).forEach(([key, value]) => {
      SkillUser_MapEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SkillUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSkillUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = SkillUser_MapEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.map[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SkillUser {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      type: isSet(object.type) ? skillTypeFromJSON(object.type) : undefined,
      map: isObject(object.map)
        ? Object.entries(object.map).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
          acc[key] = Boolean(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: SkillUser): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined && (obj.type = message.type !== undefined ? skillTypeToJSON(message.type) : undefined);
    obj.map = {};
    if (message.map) {
      Object.entries(message.map).forEach(([k, v]) => {
        obj.map[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SkillUser>, I>>(base?: I): SkillUser {
    return SkillUser.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkillUser>, I>>(object: I): SkillUser {
    const message = createBaseSkillUser();
    message.id = object.id ?? undefined;
    message.type = object.type ?? undefined;
    message.map = Object.entries(object.map ?? {}).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Boolean(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseSkillUser_MapEntry(): SkillUser_MapEntry {
  return { key: "", value: false };
}

export const SkillUser_MapEntry = {
  encode(message: SkillUser_MapEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value === true) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SkillUser_MapEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSkillUser_MapEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SkillUser_MapEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Boolean(object.value) : false,
    };
  },

  toJSON(message: SkillUser_MapEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkillUser_MapEntry>, I>>(base?: I): SkillUser_MapEntry {
    return SkillUser_MapEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkillUser_MapEntry>, I>>(object: I): SkillUser_MapEntry {
    const message = createBaseSkillUser_MapEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseReferenceContainer(): ReferenceContainer {
  return { id: undefined, name: undefined, isCustom: undefined };
}

export const ReferenceContainer = {
  encode(message: ReferenceContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.isCustom !== undefined) {
      writer.uint32(24).bool(message.isCustom);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReferenceContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReferenceContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isCustom = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReferenceContainer {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      isCustom: isSet(object.isCustom) ? Boolean(object.isCustom) : undefined,
    };
  },

  toJSON(message: ReferenceContainer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.isCustom !== undefined && (obj.isCustom = message.isCustom);
    return obj;
  },

  create<I extends Exact<DeepPartial<ReferenceContainer>, I>>(base?: I): ReferenceContainer {
    return ReferenceContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ReferenceContainer>, I>>(object: I): ReferenceContainer {
    const message = createBaseReferenceContainer();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.isCustom = object.isCustom ?? undefined;
    return message;
  },
};

function createBaseUserIdContainer(): UserIdContainer {
  return { id: undefined };
}

export const UserIdContainer = {
  encode(message: UserIdContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserIdContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserIdContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserIdContainer {
    return { id: isSet(object.id) ? String(object.id) : undefined };
  },

  toJSON(message: UserIdContainer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserIdContainer>, I>>(base?: I): UserIdContainer {
    return UserIdContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserIdContainer>, I>>(object: I): UserIdContainer {
    const message = createBaseUserIdContainer();
    message.id = object.id ?? undefined;
    return message;
  },
};

function createBaseDialerWebRtc(): DialerWebRtc {
  return { user: undefined, pass: undefined, connection: undefined };
}

export const DialerWebRtc = {
  encode(message: DialerWebRtc, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      writer.uint32(10).string(message.user);
    }
    if (message.pass !== undefined) {
      writer.uint32(18).string(message.pass);
    }
    if (message.connection !== undefined) {
      writer.uint32(26).string(message.connection);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerWebRtc {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerWebRtc();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pass = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.connection = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerWebRtc {
    return {
      user: isSet(object.user) ? String(object.user) : undefined,
      pass: isSet(object.pass) ? String(object.pass) : undefined,
      connection: isSet(object.connection) ? String(object.connection) : undefined,
    };
  },

  toJSON(message: DialerWebRtc): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user);
    message.pass !== undefined && (obj.pass = message.pass);
    message.connection !== undefined && (obj.connection = message.connection);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerWebRtc>, I>>(base?: I): DialerWebRtc {
    return DialerWebRtc.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerWebRtc>, I>>(object: I): DialerWebRtc {
    const message = createBaseDialerWebRtc();
    message.user = object.user ?? undefined;
    message.pass = object.pass ?? undefined;
    message.connection = object.connection ?? undefined;
    return message;
  },
};

function createBaseDialerSettings(): DialerSettings {
  return { callCap: undefined };
}

export const DialerSettings = {
  encode(message: DialerSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.callCap !== undefined) {
      DialerCallCap.encode(message.callCap, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.callCap = DialerCallCap.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerSettings {
    return { callCap: isSet(object.callCap) ? DialerCallCap.fromJSON(object.callCap) : undefined };
  },

  toJSON(message: DialerSettings): unknown {
    const obj: any = {};
    message.callCap !== undefined &&
      (obj.callCap = message.callCap ? DialerCallCap.toJSON(message.callCap) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerSettings>, I>>(base?: I): DialerSettings {
    return DialerSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerSettings>, I>>(object: I): DialerSettings {
    const message = createBaseDialerSettings();
    message.callCap = (object.callCap !== undefined && object.callCap !== null)
      ? DialerCallCap.fromPartial(object.callCap)
      : undefined;
    return message;
  },
};

function createBaseDialerCallCap(): DialerCallCap {
  return { enabled: undefined, allMustApply: undefined, todayCalls: undefined, todayCallsBillable: undefined };
}

export const DialerCallCap = {
  encode(message: DialerCallCap, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== undefined) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.allMustApply !== undefined) {
      writer.uint32(16).bool(message.allMustApply);
    }
    if (message.todayCalls !== undefined) {
      writer.uint32(25).double(message.todayCalls);
    }
    if (message.todayCallsBillable !== undefined) {
      writer.uint32(33).double(message.todayCallsBillable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCallCap {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCallCap();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.allMustApply = reader.bool();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.todayCalls = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.todayCallsBillable = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCallCap {
    return {
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : undefined,
      allMustApply: isSet(object.allMustApply) ? Boolean(object.allMustApply) : undefined,
      todayCalls: isSet(object.todayCalls) ? Number(object.todayCalls) : undefined,
      todayCallsBillable: isSet(object.todayCallsBillable) ? Number(object.todayCallsBillable) : undefined,
    };
  },

  toJSON(message: DialerCallCap): unknown {
    const obj: any = {};
    message.enabled !== undefined && (obj.enabled = message.enabled);
    message.allMustApply !== undefined && (obj.allMustApply = message.allMustApply);
    message.todayCalls !== undefined && (obj.todayCalls = message.todayCalls);
    message.todayCallsBillable !== undefined && (obj.todayCallsBillable = message.todayCallsBillable);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCallCap>, I>>(base?: I): DialerCallCap {
    return DialerCallCap.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCallCap>, I>>(object: I): DialerCallCap {
    const message = createBaseDialerCallCap();
    message.enabled = object.enabled ?? undefined;
    message.allMustApply = object.allMustApply ?? undefined;
    message.todayCalls = object.todayCalls ?? undefined;
    message.todayCallsBillable = object.todayCallsBillable ?? undefined;
    return message;
  },
};

function createBaseSkillListContainer(): SkillListContainer {
  return { byId: {}, list: [], listByType: [] };
}

export const SkillListContainer = {
  encode(message: SkillListContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.byId).forEach(([key, value]) => {
      SkillListContainer_ByIdEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    for (const v of message.list) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.listByType) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SkillListContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSkillListContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = SkillListContainer_ByIdEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.byId[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.list.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.listByType.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SkillListContainer {
    return {
      byId: isObject(object.byId)
        ? Object.entries(object.byId).reduce<{ [key: string]: SkillUser }>((acc, [key, value]) => {
          acc[key] = SkillUser.fromJSON(value);
          return acc;
        }, {})
        : {},
      list: Array.isArray(object?.list) ? object.list.map((e: any) => String(e)) : [],
      listByType: Array.isArray(object?.listByType) ? object.listByType.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: SkillListContainer): unknown {
    const obj: any = {};
    obj.byId = {};
    if (message.byId) {
      Object.entries(message.byId).forEach(([k, v]) => {
        obj.byId[k] = SkillUser.toJSON(v);
      });
    }
    if (message.list) {
      obj.list = message.list.map((e) => e);
    } else {
      obj.list = [];
    }
    if (message.listByType) {
      obj.listByType = message.listByType.map((e) => e);
    } else {
      obj.listByType = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SkillListContainer>, I>>(base?: I): SkillListContainer {
    return SkillListContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkillListContainer>, I>>(object: I): SkillListContainer {
    const message = createBaseSkillListContainer();
    message.byId = Object.entries(object.byId ?? {}).reduce<{ [key: string]: SkillUser }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = SkillUser.fromPartial(value);
      }
      return acc;
    }, {});
    message.list = object.list?.map((e) => e) || [];
    message.listByType = object.listByType?.map((e) => e) || [];
    return message;
  },
};

function createBaseSkillListContainer_ByIdEntry(): SkillListContainer_ByIdEntry {
  return { key: "", value: undefined };
}

export const SkillListContainer_ByIdEntry = {
  encode(message: SkillListContainer_ByIdEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      SkillUser.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SkillListContainer_ByIdEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSkillListContainer_ByIdEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = SkillUser.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SkillListContainer_ByIdEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? SkillUser.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: SkillListContainer_ByIdEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? SkillUser.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkillListContainer_ByIdEntry>, I>>(base?: I): SkillListContainer_ByIdEntry {
    return SkillListContainer_ByIdEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkillListContainer_ByIdEntry>, I>>(object: I): SkillListContainer_ByIdEntry {
    const message = createBaseSkillListContainer_ByIdEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? SkillUser.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseStringListContainer(): StringListContainer {
  return { list: [] };
}

export const StringListContainer = {
  encode(message: StringListContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.list) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StringListContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStringListContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StringListContainer {
    return { list: Array.isArray(object?.list) ? object.list.map((e: any) => String(e)) : [] };
  },

  toJSON(message: StringListContainer): unknown {
    const obj: any = {};
    if (message.list) {
      obj.list = message.list.map((e) => e);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StringListContainer>, I>>(base?: I): StringListContainer {
    return StringListContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StringListContainer>, I>>(object: I): StringListContainer {
    const message = createBaseStringListContainer();
    message.list = object.list?.map((e) => e) || [];
    return message;
  },
};

function createBaseEnrollmentCodeContainer(): EnrollmentCodeContainer {
  return { byCall: {} };
}

export const EnrollmentCodeContainer = {
  encode(message: EnrollmentCodeContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.byCall).forEach(([key, value]) => {
      EnrollmentCodeContainer_ByCallEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentCodeContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentCodeContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = EnrollmentCodeContainer_ByCallEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.byCall[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentCodeContainer {
    return {
      byCall: isObject(object.byCall)
        ? Object.entries(object.byCall).reduce<{ [key: string]: EnrollmentCode }>((acc, [key, value]) => {
          acc[key] = EnrollmentCode.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: EnrollmentCodeContainer): unknown {
    const obj: any = {};
    obj.byCall = {};
    if (message.byCall) {
      Object.entries(message.byCall).forEach(([k, v]) => {
        obj.byCall[k] = EnrollmentCode.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentCodeContainer>, I>>(base?: I): EnrollmentCodeContainer {
    return EnrollmentCodeContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentCodeContainer>, I>>(object: I): EnrollmentCodeContainer {
    const message = createBaseEnrollmentCodeContainer();
    message.byCall = Object.entries(object.byCall ?? {}).reduce<{ [key: string]: EnrollmentCode }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = EnrollmentCode.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseEnrollmentCodeContainer_ByCallEntry(): EnrollmentCodeContainer_ByCallEntry {
  return { key: "", value: undefined };
}

export const EnrollmentCodeContainer_ByCallEntry = {
  encode(message: EnrollmentCodeContainer_ByCallEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      EnrollmentCode.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentCodeContainer_ByCallEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentCodeContainer_ByCallEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = EnrollmentCode.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentCodeContainer_ByCallEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? EnrollmentCode.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: EnrollmentCodeContainer_ByCallEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? EnrollmentCode.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentCodeContainer_ByCallEntry>, I>>(
    base?: I,
  ): EnrollmentCodeContainer_ByCallEntry {
    return EnrollmentCodeContainer_ByCallEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentCodeContainer_ByCallEntry>, I>>(
    object: I,
  ): EnrollmentCodeContainer_ByCallEntry {
    const message = createBaseEnrollmentCodeContainer_ByCallEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? EnrollmentCode.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseEnrollmentCode(): EnrollmentCode {
  return { code: undefined, source: undefined, expiresAt: undefined, note: undefined, skip: undefined };
}

export const EnrollmentCode = {
  encode(message: EnrollmentCode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== undefined) {
      writer.uint32(10).string(message.code);
    }
    if (message.source !== undefined) {
      writer.uint32(16).int32(message.source);
    }
    if (message.expiresAt !== undefined) {
      writer.uint32(24).int64(message.expiresAt);
    }
    if (message.note !== undefined) {
      writer.uint32(34).string(message.note);
    }
    if (message.skip !== undefined) {
      writer.uint32(40).bool(message.skip);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentCode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentCode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.expiresAt = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.note = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.skip = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentCode {
    return {
      code: isSet(object.code) ? String(object.code) : undefined,
      source: isSet(object.source) ? enrollmentSourceFromJSON(object.source) : undefined,
      expiresAt: isSet(object.expiresAt) ? Number(object.expiresAt) : undefined,
      note: isSet(object.note) ? String(object.note) : undefined,
      skip: isSet(object.skip) ? Boolean(object.skip) : undefined,
    };
  },

  toJSON(message: EnrollmentCode): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    message.source !== undefined &&
      (obj.source = message.source !== undefined ? enrollmentSourceToJSON(message.source) : undefined);
    message.expiresAt !== undefined && (obj.expiresAt = Math.round(message.expiresAt));
    message.note !== undefined && (obj.note = message.note);
    message.skip !== undefined && (obj.skip = message.skip);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentCode>, I>>(base?: I): EnrollmentCode {
    return EnrollmentCode.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentCode>, I>>(object: I): EnrollmentCode {
    const message = createBaseEnrollmentCode();
    message.code = object.code ?? undefined;
    message.source = object.source ?? undefined;
    message.expiresAt = object.expiresAt ?? undefined;
    message.note = object.note ?? undefined;
    message.skip = object.skip ?? undefined;
    return message;
  },
};

function createBaseDigitInputConfiguration(): DigitInputConfiguration {
  return {
    digits: undefined,
    transform: undefined,
    allowNumbers: undefined,
    allowSpecialCharacters: undefined,
    allowLetters: undefined,
  };
}

export const DigitInputConfiguration = {
  encode(message: DigitInputConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.digits !== undefined) {
      writer.uint32(8).int32(message.digits);
    }
    if (message.transform !== undefined) {
      writer.uint32(16).int32(message.transform);
    }
    if (message.allowNumbers !== undefined) {
      writer.uint32(24).bool(message.allowNumbers);
    }
    if (message.allowSpecialCharacters !== undefined) {
      writer.uint32(32).bool(message.allowSpecialCharacters);
    }
    if (message.allowLetters !== undefined) {
      writer.uint32(40).bool(message.allowLetters);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DigitInputConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDigitInputConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.digits = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.transform = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.allowNumbers = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.allowSpecialCharacters = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.allowLetters = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DigitInputConfiguration {
    return {
      digits: isSet(object.digits) ? Number(object.digits) : undefined,
      transform: isSet(object.transform) ? digitInputTransformFromJSON(object.transform) : undefined,
      allowNumbers: isSet(object.allowNumbers) ? Boolean(object.allowNumbers) : undefined,
      allowSpecialCharacters: isSet(object.allowSpecialCharacters) ? Boolean(object.allowSpecialCharacters) : undefined,
      allowLetters: isSet(object.allowLetters) ? Boolean(object.allowLetters) : undefined,
    };
  },

  toJSON(message: DigitInputConfiguration): unknown {
    const obj: any = {};
    message.digits !== undefined && (obj.digits = Math.round(message.digits));
    message.transform !== undefined &&
      (obj.transform = message.transform !== undefined ? digitInputTransformToJSON(message.transform) : undefined);
    message.allowNumbers !== undefined && (obj.allowNumbers = message.allowNumbers);
    message.allowSpecialCharacters !== undefined && (obj.allowSpecialCharacters = message.allowSpecialCharacters);
    message.allowLetters !== undefined && (obj.allowLetters = message.allowLetters);
    return obj;
  },

  create<I extends Exact<DeepPartial<DigitInputConfiguration>, I>>(base?: I): DigitInputConfiguration {
    return DigitInputConfiguration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DigitInputConfiguration>, I>>(object: I): DigitInputConfiguration {
    const message = createBaseDigitInputConfiguration();
    message.digits = object.digits ?? undefined;
    message.transform = object.transform ?? undefined;
    message.allowNumbers = object.allowNumbers ?? undefined;
    message.allowSpecialCharacters = object.allowSpecialCharacters ?? undefined;
    message.allowLetters = object.allowLetters ?? undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
